<template>  
    <v-card>
      <v-toolbar color="danger" dense>
        <v-toolbar-title>{{ $t('doubleAuth.DeletionConfirmation') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <span>{{ $t('doubleAuth.AlertMessageDelete') }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal" >{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="error" @click="deleteConfig" >{{ $t('actions.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>

import DroitService from "@/Services/SupportVision/DroitService";
import * as Vuex from "vuex";

export default {
  name: 'rightDeletes',
  data: () => ({
    alertDelete: false,
  }), 
  computed: {
    ...Vuex.mapState(["droitTag"]),
  }, 
  methods: {
    closeModal() {
      this.$emit('close-delete-modal');
    },
    async deleteConfig() {
      await DroitService.removeDroit(this.droitTag);
      this.$nInfo('Droit supprimé');
      this.$emit('rightDeleted');
      this.$emit('close-delete-modal');
    },
  }
}
</script>


