<template>
  <v-card :key="modalKey">
    <v-toolbar class="mb-2" color="primary" dark>
      <v-toolbar-title class="text-h5" elevation="20">
      <span class="text-h5">Droits</span> 
    </v-toolbar-title>
    <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="droitTag"
            label="Tag de la configuration*"
            :rules="[tagRule]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            ref="userSelect"
            :items="users"
            :item-text="item => `${item.firstname} ${item.lastname}`"
            :item-value="item => item.id"
            :label="'Membres GTP*'"
            multiple
            v-model="usersSelected"
            :rules="[userSelectRule]"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            :items="menuTitle"
            label="Selectionner les menus à attribuer"
            multiple
            v-model="menuSelected"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        @click="closeModal"
      >
        Close
      </v-btn>
      <v-btn
        color="success"
        variant="text"
        @click="saveAndCloseModal"
        :disabled="!isValidate"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/Services/UserService';
import DroitService from "@/Services/SupportVision/DroitService";
import * as Vuex from "vuex";

export default {
  name: "rightsCreate",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect")
  },
  computed: { 
    ...Vuex.mapState(["itemsMenu"]),
    ...Vuex.mapMutations(["setInformations"]),
    menuTitle() {
      return this.itemsMenu;
    }, 
    isValidate() {
      return this.droitTag.length > 0 && this.usersSelected.length > 0;
    }
  },
  data: () => ({
    dialog: false,
    users: [],
    usersSelected: [],
    menuSelected: [],
    droitTag: '',
    modalKey: 0,
    tagRule: (v) => !!v || 'Ce champ est requis',
    userSelectRule: (v) => v && v.length > 0 || 'Ce champ est requis'
  }),
  methods: {
    closeModal() {
      // Réinitialiser les champs spécifiques
      this.droitTag = '';
      this.usersSelected = [];
      this.menuSelected = [];
      // Réinitialiser les règles spécifiques
      this.tagRule = (v) => !!v || 'Ce champ est requis';
      this.userSelectRule = (v) => v && v.length > 0 || 'Ce champ est requis';
      // Forcer la réinitialisation complète du composant 
      this.modalKey += 1; 
      this.$emit('close-modal');
    }, 
    async saveAndCloseModal() { 
      let dataRight = {
        users: this.usersSelected,
        menus: this.menuSelected,
        tag: this.droitTag
      }
      const res = await DroitService.postDroit(dataRight).then((res) => {
      }); 
      this.$emit('rightCreated');
      this.closeModal();
    }
  },
  async mounted() {
    const users = await UserService.getUsers().then((res) => {
      this.users = res;
    });
  },
};
</script>
