<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">Gestion de droits</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <slot name="rightsCreate">
        <v-btn @click="openModal" color="primary" small :disabled="!isSuperAdmin">Créer</v-btn> 
      </slot> -->
      <!-- menu avec la creation et la liste des parametrage -->
      <v-menu offset-y max-width="250">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon v-if="isSuperAdmin">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list min-width="250px">
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="openModal"
              :class="!isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'"
            >
              Droits
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              v-if="basesParams.length > 0"
              @click="openModalListParametrage"
              :class="!isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'"
            >
              Liste paramétrages
            </v-list-item-title>
            <v-list-item-title
              v-else
              @click="openModalParametrage"
              :class="!isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'"
            >
              Paramétrages
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!-- si pas le droit mettre un msg  -->
    <v-col cols="4" v-if="droit.length === 0">
      <v-alert
        v-if="droit.length === 0"
        type="warning"
        prominent
        border="left"
        elevation="2"
        colored-border
      >
        Aucun droit n'a été créé ou vous n'avez pas accès à cette interface.
      </v-alert>
    </v-col>
    <v-row v-if="droit.length > 0">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchUser"
          label="Rechercher par utilisateur"
          @input="filterUsers"
          prepend-icon="mdi-account-search"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchMenu"
          label="Rechercher par menu"
          @input="filterMenus"
          prepend-icon="mdi-text-search"
        />
      </v-col>
    </v-row>
    <v-card v-if="droit.length > 0">
      <v-tabs v-model="tab" background-color="primary" centered dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(user, userIndex) in droit"
          :key="userIndex"
          :href="'#tab-' + user.droit.tag"
        >
          {{ user.droit.tag }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(user, userIndex) in droit"
          :key="userIndex"
          :value="'tab-' + user.droit.tag"
        >
          <v-card flat>
            <v-card-text
              class="d-flex justify-space-between"
              :style="{ color: $vuetify.theme.dark ? '#FFFFFF' : '#000000' }"
            >
              <!-- separer en deux liste les users et les menu -->
              <div class="d-flex align-center" style="gap: 1rem">
                <v-icon size="30" :color="$vuetify.theme.dark ? '#FFFFFF' : '#000000'"
                  >mdi-account-group</v-icon
                >
                <v-divider vertical></v-divider>
                <div>
                  <!-- icon de groupe de users -->
                  <div
                    class="d-flex flex-column"
                    v-for="(user, userIndex) in user.droit.users"
                    :key="userIndex"
                  >
                    {{ usersName[user] }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-center" style="gap: 1rem">
                <v-icon size="30" :color="$vuetify.theme.dark ? '#FFFFFF' : '#000000'"
                  >mdi-telescope</v-icon
                >
                <v-divider vertical></v-divider>
                <div v-if="user.droit.menus.length > 0">
                  <div v-for="(menu, menuIndex) in user.droit.menus" :key="menuIndex">
                    {{ menu }}
                  </div>
                </div>
                <div v-else style="color: #fb8c00; font-weight: bold">
                  Aucun menu autorisé
                </div>
              </div>
              <div class="d-flex align-center" v-if="isSuperAdmin">
                <v-btn
                  color="primary"
                  class="mr-2"
                  small
                  @click="openModalEdit(user.droit.tag)"
                  :disabled="!isSuperAdmin"
                  >Modifier</v-btn
                >
                <v-btn
                  color="error"
                  small
                  @click="openModalDelete(user.droit.tag)"
                  :disabled="!isSuperAdmin"
                  >Supprimer</v-btn
                >
              </div>
              <!-- sinon on ne voit pas les boutons mais garder le css -->
              <div class="d-flex align-center" v-else></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialog" max-width="50%">
      <rightsCreate @close-modal="closeModal" @rightCreated="updateRightsList" />
    </v-dialog>
    <!-- modal edition -->
    <v-dialog v-model="dialogEdit" max-width="50%">
      <rightsEdit
        @close-edit-modal="dialogEdit = false"
        @rightEdited="updateRightsList"
      />
    </v-dialog>
    <!-- modal suppression -->
    <v-dialog v-model="dialogDelete" max-width="30%">
      <rightsDelete
        @close-delete-modal="dialogDelete = false"
        @rightDeleted="updateRightsList"
      />
    </v-dialog>
    <!-- modal de parametrage -->
    <v-dialog v-model="dialogParametrage" max-width="50%">
      <basesParametrage @close-modal="dialogParametrage = false" />
    </v-dialog>
    <!-- modale liste paramertage -->
    <v-dialog v-model="dialogListParametrage" max-width="80%">
      <listesParametrage @close-modal="dialogListParametrage = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import rightsCreate from "./rightsCreate.vue";
import rightsEdit from "./rightsEdit.vue";
import UserService from "@/Services/UserService";
import DroitService from "@/Services/SupportVision/DroitService";
import rightsDelete from "./rightsDelete.vue";
import basesParametrage from "@/Components/Views/SupportVision/Bases/basesParametrage.vue";
import listesParametrage from "@/Components/Views/SupportVision/Bases/listesParametrage.vue";
import BasesService from "@/Services/SupportVision/BasesService";

import * as Vuex from "vuex";

export default {
  name: "Clients",
  data() {
    return {
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogParametrage: false,
      dialogListParametrage: false,
      users: [],
      droit: {},
      menusInterface: [],
      tab: null,
      searchUser: "",
      searchMenu: "",
    };
  },
  components: {
    rightsCreate,
    rightsEdit,
    rightsDelete,
    basesParametrage,
    listesParametrage,
  },
  computed: {
    ...Vuex.mapMutations([
      "setDroitSupportVision",
      "setItemsMenu",
      "setUsersGtp",
      "setDroitTag",
    ]),
    ...Vuex.mapState(["droitSupportVision", "informations", "basesParams"]),
    // retourne le nom prenom des users via leur id
    usersName() {
      return this.users.reduce((acc, user) => {
        acc[user.id] = user.firstname + " " + user.lastname;
        return acc;
      }, {});
    },
    // si tag SUPERADMIN
    isSuperAdmin() {
      return (
        Array.isArray(this.droitSupportVision) &&
        this.droitSupportVision.some(
          (user) => user.droit.tag === "SUPERADMIN" && user.base == this.getDns()
        ) &&
        Array.isArray(this.droitSupportVision) &&
        this.droitSupportVision.some((userId) =>
          userId.droit.users.includes(this.informations.id)
        )
      );
    },
  },
  methods: {
    openModal() {
      // !this.isSuperAdmin ? (this.dialog = false && this.$nError('Vous n\'avez pas les droits pour créer un droit')):
      // this.dialog = true
      if (!this.isSuperAdmin) {
        this.dialog = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.dialog = true;
      }
    },
    closeModal() {
      this.dialog = false;
    },
    openModalEdit(tag) {
      this.$store.commit("setDroitTag", tag);
      this.dialogEdit = true;
    },
    openModalDelete(tag) {
      this.$store.commit("setDroitTag", tag);
      this.dialogDelete = true;
    },
    openModalParametrage() {
      // !this.isSuperAdmin ? this.dialogParametrage = false :
      // this.dialogParametrage = true
      if (!this.isSuperAdmin) {
        this.dialogParametrage = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.dialogParametrage = true;
      }
    },
    openModalListParametrage() {
      // !this.isSuperAdmin ? this.dialogListParametrage = false :
      // this.dialogListParametrage = true
      if (!this.isSuperAdmin) {
        this.dialogListParametrage = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.dialogListParametrage = true;
      }
    },
    async updateRightsList() {
      // Update the list of rights after a new right is created
      const data = await DroitService.getDroit().then((res) => {
        this.$store.commit("setDroitSupportVision", res);
        this.droit = this.droitSupportVision;
      });
    },
    async getMenusInterface() {
      const menusInterface = await DroitService.getMenuInterface().then((res) => {
        // retourner que le titre des menus
        this.menusInterface = res.menusInterface.map((item) => item.title);
        this.$store.commit("setItemsMenu", this.menusInterface);
      });
    },
    normalizeString(str) {
      // Fonction pour normaliser une chaîne en retirant les accents et en la mettant en minuscules
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
    filterUsers() {
      const searchTerm = this.normalizeString(this.searchUser);
      this.droit = this.droitSupportVision.filter((user) => {
        return user.droit.users.some((userId) =>
          this.normalizeString(this.usersName[userId]).includes(searchTerm)
        );
      });
    },
    filterMenus() {
      const searchTerm = this.normalizeString(this.searchMenu);
      this.droit = this.droitSupportVision.filter((user) => {
        return user.droit.menus.some((menu) =>
          this.normalizeString(menu).includes(searchTerm)
        );
      });
    },
  },
  async mounted() {
    const users = await UserService.getUsers().then((res) => {
      this.users = res;
      this.$store.commit("setUsersGtp", res);
    });
    const data = await DroitService.getDroit().then((res) => {
      res == "No rights found"
        ? this.$store.commit("setDroitSupportVision", [])
        : this.$store.commit("setDroitSupportVision", res);
      this.droit = this.droitSupportVision;
    });
    const params = await BasesService.getParams();
    this.$store.commit("setBasesParams", params);
    this.getMenusInterface();
  },
};
</script>
