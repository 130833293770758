<template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifier les droits</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
            <v-autocomplete
                ref="userSelect"
                :items="users"
                :item-text="item => `${item.firstname} ${item.lastname}`"
                :item-value="item => item.id"
                :label="'Membres GTP'"
                multiple
                v-model="usersSelected"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                :items="menuTitle"
                label="Selectionner les menus à attribuer"
                multiple
                v-model="menuSelected"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeModal"
          >
            Close
          </v-btn>
          <v-btn
            color="warning"
            variant="text"
            @click="saveAndCloseModal"
          >
            Editer
          </v-btn>
        </v-card-actions>
      </v-card>
</template>
<script>
import UserService from '@/Services/UserService';
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import DroitService from "@/Services/SupportVision/DroitService";

import * as Vuex from "vuex";

  export default {
    name: "rightsEdit",
    components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect")
    },
    computed: { 
      ...Vuex.mapState(["itemsMenu", "droitTag"]),
      ...Vuex.mapMutations(["setInformations"]),
      menuTitle() {
        return this.itemsMenu;
      }, 
    },
    // load data a chaque fois que le tag change
    watch: {
      droitTag: function() {
        this.loadDroitData();
      }
    },
    data: () => ({
      dialog: false,
      users: [],
      usersSelected: [],
      menuSelected: [],
    }),
    methods: {
      closeModal() {
        this.dialog = false;
        this.$emit('close-edit-modal');
      }, 
      async loadDroitData() {
      try {
        const data = await DroitService.getDroitByTag(this.droitTag);
        this.usersSelected = data.droit.users;
        this.menuSelected = data.droit.menus;
      } catch (error) {
        console.error("Erreur lors du chargement des données Droit:", error);
      }
    },
      async saveAndCloseModal() { 
        // save
        let dataRight = {
          users: this.usersSelected,
          menus: this.menuSelected,
          tag: this.droitTag
        }
        const res = await DroitService.putDroit(dataRight).then((res) => {
          // commit
          this.$store.commit("setDroitSupportVision", res);
        });
        this.$emit('rightEdited');
        this.closeModal();
      }
    },
    async mounted() {
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.loadDroitData();
    },
  }
</script>